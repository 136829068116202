import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderSlot as _renderSlot } from "vue";
const _hoisted_1 = { class: "z-h4 auth-section__title" };
const _hoisted_2 = {
    key: 2,
    class: "z-b3 error-component"
};
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = { class: "auth-section__form-container" };
const _hoisted_5 = { class: "auth-section__footer-container" };
import FacebookHandler from "@components/Login/Facebook/FacebookHandler.vue";
import AppleHandler from "@components/Login/Apple/AppleHandler.vue";
import { computed, ref } from "vue";
import GoogleHandler from "@components/Login/Google/GoogleHandler.vue";
import { mixpanelServiceTrack } from '@ts/Util/analytics';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AuthSection',
    props: {
        t: {
            type: Object,
            default: () => ({
                title: '',
                subtitle: '',
                loginWithText: '',
            })
        },
        appleCredentials: {
            type: Object,
            required: true
        },
        facebookCredentials: {
            type: Object,
            required: true
        },
        googleCredentials: {
            type: Object,
            required: true
        },
        allowsSsoAccountCreation: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: '',
        },
        compact: {
            type: Boolean,
            default: false,
        },
        leftAligned: {
            type: Boolean,
            default: false,
        },
        isWebview: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['successful', 'failure', 'loginStarted'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const classes = computed(() => ({
            'auth-section': true,
            'auth-section__compact': props.compact,
            'left-aligned': props.leftAligned,
        }));
        const beforeAuthenticationSuccessful = (event) => emit('successful', event);
        const beforeAuthenticationFailure = (event) => emit('failure', event);
        const beforeAuthenticationStart = (event) => emit('loginStarted', event);
        const started = ref(false);
        const handleAuthenticationClick = (provider) => {
            if (!started.value) {
                started.value = true;
                mixpanelServiceTrack('TrackCreateAccountStarted', {
                    'createAuthMethod': provider,
                });
            }
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("section", {
                class: _normalizeClass(classes.value)
            }, [
                _createElementVNode("h1", _hoisted_1, _toDisplayString(props.t.title), 1),
                (!__props.isWebview)
                    ? (_openBlock(), _createElementBlock("p", {
                        key: 0,
                        class: _normalizeClass(["z-b3 auth-section__text", { 'left-aligned': props.leftAligned }])
                    }, _toDisplayString(props.t.subtitle), 3))
                    : _createCommentVNode("", true),
                (!__props.isWebview)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(["auth-section__icons", { 'left-aligned': props.leftAligned }])
                    }, [
                        _createVNode(GoogleHandler, {
                            class: "auth-section__icons__google-icon",
                            "client-id": props.googleCredentials.clientID,
                            "allows-account-creation": props.allowsSsoAccountCreation,
                            onSuccessful: beforeAuthenticationSuccessful,
                            onFailure: beforeAuthenticationFailure,
                            onLoginStarted: beforeAuthenticationStart,
                            onClick: _cache[0] || (_cache[0] = ($event) => (handleAuthenticationClick('google')))
                        }, null, 8, ["client-id", "allows-account-creation"]),
                        _createVNode(FacebookHandler, {
                            class: "auth-section__icons__facebook-icon",
                            "app-id": props.facebookCredentials.appId,
                            "allows-account-creation": props.allowsSsoAccountCreation,
                            onSuccessful: beforeAuthenticationSuccessful,
                            onFailure: beforeAuthenticationFailure,
                            onLoginStarted: beforeAuthenticationStart,
                            onClick: _cache[1] || (_cache[1] = ($event) => (handleAuthenticationClick('facebook')))
                        }, null, 8, ["app-id", "allows-account-creation"]),
                        _createVNode(AppleHandler, {
                            class: "auth-section__icons__apple-icon",
                            "client-id": props.appleCredentials.clientID,
                            "redirect-uri": props.appleCredentials.redirectURI,
                            "allows-account-creation": props.allowsSsoAccountCreation,
                            onSuccessful: beforeAuthenticationSuccessful,
                            onFailure: beforeAuthenticationFailure,
                            onLoginStarted: beforeAuthenticationStart,
                            onClick: _cache[2] || (_cache[2] = ($event) => (handleAuthenticationClick('apple')))
                        }, null, 8, ["client-id", "redirect-uri", "allows-account-creation"]),
                        _renderSlot(_ctx.$slots, "header-buttons")
                    ], 2))
                    : _createCommentVNode("", true),
                (props.error)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createElementVNode("span", {
                            class: "error-component__text",
                            innerHTML: props.error
                        }, null, 8, _hoisted_3)
                    ]))
                    : _createCommentVNode("", true),
                _createElementVNode("h2", {
                    class: _normalizeClass(["auth-section__container", { 'left-aligned': props.leftAligned }])
                }, [
                    (!__props.isWebview)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass(["z-b3 auth-section__text", { 'left-aligned': props.leftAligned }])
                        }, _toDisplayString(props.t.loginWithText), 3))
                        : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", _hoisted_4, [
                    _renderSlot(_ctx.$slots, "form")
                ]),
                _createElementVNode("div", _hoisted_5, [
                    _renderSlot(_ctx.$slots, "footer")
                ])
            ], 2));
        };
    }
});
